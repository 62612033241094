








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterNOGFilter extends Vue {
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;

    get value() {
        return this.clusterRatesService.storeState.settings.numberOfGuests;
    }

    set value(value: number) {
        this.clusterRatesService.storeState.settings.numberOfGuests = value;
    }

    get items(): Item[] {
        return Array
            .from({ length: 10 })
            .map((e, i) => i + 1)
            .map(e => ({
                value: e,
                name: String(e) + (e > 1 ? ' guests' : ' guest'),
            }));
    }
}
