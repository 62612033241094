








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterRoomTypeFilter extends Vue {
    @Inject(ClusterRatesServiceS)
    public clusterRatesService!: ClusterRatesService;

    @Inject(MealTypesServiceS)
    public mealTypesService!: MealTypesService;

    @Inject(RatesFiltersServiceS)
    public ratesFiltersService!: RatesFiltersService;

    get value() {
        return this.clusterRatesService.storeState.settings.mealTypeId;
    }

    set value(value: number) {
        this.clusterRatesService.storeState.settings.mealTypeId = value;
        this.ratesFiltersService.settings.mealTypeId = value;
    }

    get items(): Item[] {
        return this.mealTypesService.mealTypes
            .map(meal => ({
                value: meal.id,
                name: meal.displayName,
            }));
    }
}
