var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cluster-list"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cluster-list__header-info"},[_c('div',{class:{
                    'cluster-list__column-header': true,
                    'cluster-list__column-header--hotel-name': true,
                    'cluster-list__column-header--sortable': true,
                    'cluster-list__column-header--current-sort': !_vm.isRateSortingActive,
                    skeleton: _vm.skeleton
                },on:{"click":_vm.sortByABC}},[_vm._v(" Hotel name "),_c('img',{class:{
                        'sort-icon': true,
                        'sort-icon--asc': _vm.isHotelNameAscendingSort,
                    },attrs:{"src":require("@/modules/common/assets/sort.svg")}})]),_c('div',{class:{
                    'cluster-list__column-header': true,
                    'cluster-list__column-header--rate': true,
                    'cluster-list__column-header--sortable': true,
                    'cluster-list__column-header--current-sort': _vm.isRateSortingActive,
                    skeleton: _vm.skeleton
                },on:{"click":_vm.sort}},[_vm._v(" Rate value score "),_c('img',{class:{
                        'sort-icon': true,
                        'sort-icon--asc': _vm.isRateAscendingSort,
                    },attrs:{"src":require("@/modules/common/assets/sort.svg")}})]),_c('div',{class:{
                'skeleton': _vm.isLoading,
                'cluster-list__column-header': true,
                'cluster-list__column-header--change': true,
            }},[_vm._v(" Change ")])]),_c('div',{class:{
                'dates-scale': true,
                skeleton: _vm.skeleton
            },style:(_vm.dateScaleStyles)},_vm._l((_vm.monthDays),function(day,i){return _c('div',{key:i,class:{
                    'dates-scale__cell': true,
                    'dates-scale__cell--current': _vm.isToday(day),
                    'dates-scale__cell--bold': _vm.isMonday(day) || _vm.isSunday(day),
                }},[_vm._v(" "+_vm._s(_vm._f("WeekdayLetter")(_vm.date(day)))+_vm._s(day)+" ")])}),0)]),_c('div',{ref:"wrapper",staticClass:"wrapper",style:({ height: _vm.contentHeight }),on:{"scroll":_vm.handleScroll}},[(!_vm.hotels && _vm.isLoading)?_c('div',_vm._l((7),function(n){return _c('RatesClusterHotelItem',{key:n,style:({ opacity: 1 - n / 7 })})}),1):[_vm._l((_vm.hotels),function(item){return _c('RatesClusterHotelItem',{key:item.hotelId,attrs:{"hotelData":item}})}),_c('DotsSpinner',{attrs:{"value":_vm.isLoadingMore}})]],2),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }