



























import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import FEATURES from '@/modules/common/constants/features.constant';
import RatesHintTooltip from '@/modules/rates/components/rates-hint-tooltip.vue';
import { groupNames } from '@/modules/chain/interfaces/chain-group.enum';

@Component({
    components: {
        DateDocumentFilter,
        RatesHintTooltip,
    },
})
export default class RatesClusterHeader extends Vue {
    get isUnderChainLevel() {
        // NOTE: Enable it when chain level will avalable
        // return this.$route.path.split('/')[1] === 'chain';
        return false;
    }

    get backRoute() {
        if (!this.isUnderChainLevel) return null;

        const hierarchy = this.$route.name!.split('.');
        hierarchy.pop();

        return {
            name: hierarchy.join('.'),
        };
    }

    get feature() {
        if (this.isUnderChainLevel) {
            const groupName = groupNames[this.$route.params.group];
            return `${this.$t(FEATURES.RATE)} - ${groupName}: ${this.$route.params.value}`;
        }

        return this.$t(FEATURES.RATE);
    }
}
