




































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import Day from '@/modules/common/types/day.type';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesClusterHeader from '@/modules/cluster/components/rates/rates-cluster-header.vue';
import RatesClusterActions from '@/modules/cluster/components/rates/rates-cluster-actions.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import Loader from '@/modules/common/components/ui-kit/loader.vue';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import getScrollbarWidth from '@/modules/common/filters/scrollbar-width.filter';
import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';
import RatesClusterHotelItem from './rates-cluster-hotel-item.vue';

@Component({
    components: {
        PageWrapper,
        RatesClusterHeader,
        RatesClusterActions,
        Loader,
        DotsSpinner,
        RatesClusterHotelItem,
    },
    filters: {
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },

        WeekdayLetter,
    },
})
export default class RatesClusterList extends Vue {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    contentHeight: string | null = null;

    get skeleton() {
        return this.clusterRatesService.storeState.ratesLoading.isLoading();
    }

    get currentDay() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date();

        if (year === date.getFullYear() && month === date.getMonth()) {
            return date.getDate() - 1;
        } if (year > date.getFullYear() || month < date.getMonth()) {
            return 32;
        }
        return -1;
    }

    get hotels() {
        const { hotels } = this.clusterRatesService;

        return hotels;
    }

    get monthDays() {
        return this.documentFiltersService.days;
    }

    get dateScaleStyles() {
        const scrollWidth = getScrollbarWidth();

        return {
            paddingRight: `${scrollWidth + 15}px`,
        };
    }

    get isRateAscendingSort() {
        return this.clusterRatesService.storeState.ratesSorting === -1;
    }

    get isHotelNameAscendingSort() {
        return this.clusterRatesService.storeState.hotelNameSorting === -1;
    }

    get isRateSortingActive() {
        return !!this.clusterRatesService.storeState.ratesSorting;
    }

    get isUnderChainPair() {
        return !!this.$route.params.group;
    }

    get isLoading() {
        return this.clusterRatesService.isLoading;
    }

    get isLoadingMore() {
        return this.clusterRatesService.storeState.isLoadingMore;
    }

    mounted() {
        this.$nextTick(() => {
            this.calcHeight();
        });
        this.clusterRatesService
            .onProviderChange(() => {
                const wrapper = this.$refs.wrapper as HTMLElement;
                if (wrapper) {
                    wrapper.scrollTop = 0;
                }
            });
    }

    created() {
        window.addEventListener('resize', this.calcHeight);
    }

    destroyed() {
        window.removeEventListener('resize', this.calcHeight);
    }

    calcHeight() {
        const el: HTMLElement | null = document.querySelector('.wrapper');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            const height = window.innerHeight - (table.top + footerBox.height + 60);
            this.contentHeight = `${height}px`;
            const { limit } = this.clusterService.storeState;
            const listItemMinHeight = 108;
            this.clusterService.storeState.limit = Math.ceil(height / listItemMinHeight / limit) * limit;
        }
    }

    handleScroll(e: Event) {
        const targetElement = e.target as HTMLElement;
        const minDistanceToBottom = 10;
        const scrollMax = Math.max(
            targetElement.scrollHeight,
            targetElement.offsetHeight,
            targetElement.clientHeight,
        );

        const scrollCurrent = Math.round(window.innerHeight + Math.max(window.pageYOffset, document.documentElement.scrollTop, targetElement.scrollTop));
        const isBottomOfWindow = (scrollMax - scrollCurrent < 0) || scrollMax - scrollCurrent <= minDistanceToBottom;

        if (isBottomOfWindow) {
            this.clusterService.loadMoreData('rates');
        }
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    sort() {
        this.clusterRatesService.toggleScoreSort();
    }

    sortByABC() {
        this.clusterRatesService.toggleHotelNameSort();
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        const today = new Date();
        const date = this.date(day);

        return today.getDate() === date.getDate()
        && today.getMonth() === date.getMonth()
        && today.getFullYear() === date.getFullYear();
    }
}
