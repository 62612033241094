



































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ChainGroup from '@/modules/chain/interfaces/chain-group.enum';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesClusterHeader from '@/modules/cluster/components/rates/rates-cluster-header.vue';
import RatesClusterActions from '@/modules/cluster/components/rates/rates-cluster-actions.vue';
import RatesClusterList from '@/modules/cluster/components/rates/list/rates-cluster-list.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ChainService, { ChainServiceS } from '@/modules/chain/chain.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates/rates-filters.service';

@Component({
    components: {
        PageWrapper,
        RatesClusterHeader,
        RatesClusterActions,
        RatesClusterList,
    },
})
export default class RatesClusterListPage extends Vue {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ChainServiceS) chainService!: ChainService;

    beforeMount() {
        const { params } = this.$route;
        const { group, value } = params as {
            group: ChainGroup;
            value: string;
        };

        this.chainService.resetChainPair();

        if (group && value) {
            this.chainService.setChainPair(group, value);
        }

        this.ratesFiltersService.resetProvider();
        this.clusterService.hotels = null;
        this.clusterRatesService.resetLoading();
    }
}
