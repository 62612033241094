
import { Inject } from 'inversify-props';
import { Component, Prop } from 'vue-property-decorator';

import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import Day from '@/modules/common/types/day.type';

import CalendarChain from '@/modules/common/components/ui-kit/calendar-chain.vue';

@Component({
    extends: CalendarChain,
})
export default class RatesCalendarLine extends CalendarChain {
    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Prop({ type: Object })
    private hotelData!: ClusterHotelsRatesModel;

    get calculatedAssestments() {
        if (!this.hotelData.compsetMain) {
            return this.documentFiltersService.days.map(day => ({
                type: ASSESSMENT_TYPES.NO_DATA,
                isPastDay: this.documentFiltersService.isPreviousDay(day),
                colorModificator: this.getDayColor(ASSESSMENT_TYPES.NO_DATA),
                isNA: false,
            }));
        }

        return this.documentFiltersService.days
            .map((day, index) => {
                const type = this.clusterRatesService
                    .getAssessment(day, this.hotelData.hotelId);

                return {
                    type,
                    colorModificator: this.getDayColor(type),
                    isPastDay: this.documentFiltersService.isPreviousDay((index + 1) as Day),
                    isNA: this.notAvailableItems[index],
                    isHaveHoliday: this.hasHolidayEvents(day),
                    isHaveLocalEvents: this.hasLocalEvents(day),
                };
            });
    }

    get notAvailableItems() {
        return this.documentFiltersService.days
            .map((day: Day) => this.clusterRatesService
                .isNa(day, this.hotelData.hotelId));
    }

    getDayColor(assessmentType: ASSESSMENT_TYPES): string {
        const modificators: { [k: number]: string } = {
            [ASSESSMENT_TYPES.GOOD]: 'green',
            [ASSESSMENT_TYPES.NORMAL]: 'yellow',
            [ASSESSMENT_TYPES.BAD]: 'red',
            [ASSESSMENT_TYPES.SOLD_OUT]: 'grey',
            [ASSESSMENT_TYPES.NO_DATA]: 'white',
        };

        return modificators[assessmentType as number];
    }

    checkCell(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellhover', e.target);
        }
    }

    cellOut() {
        this.$emit('cellout');
    }

    cellClick(e: MouseEvent) {
        const isCell = (e.target as HTMLDivElement).className.includes('__cell');

        if (isCell) {
            this.$emit('cellclick', e.target);
        }
    }

    hasHolidayEvents(day: Day) {
        return this.eventsManagerService.hasHolidayEventsByDay({ day });
    }

    hasLocalEvents(day: Day) {
        return this.eventsManagerService.hasLocalEventsByDay({ day });
    }
}
