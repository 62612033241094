




import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterProviderTypeFilter extends Vue {
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get value() {
        return this.clusterService.currentProvider;
    }

    set value(value) {
        this.clusterService.saveCurrentProvider(value);
    }

    get items(): Item[] {
        const asSelectableItem = (provider: string) => ({
            value: provider,
            name: this.providersService.getProviderLabel(provider),
        });

        const withoutAllChannels = (provider: string) => provider !== 'all';

        return this.providersService.providerFilter
            .filter(withoutAllChannels)
            .map(asSelectableItem);
    }
}
