








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterLosFilter extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFilterService!: DocumentFiltersService;

    get value(): number {
        return this.documentFilterService.storeState.settings.los || 1;
    }

    set value(value: number) {
        this.documentFilterService.storeState.settings.los = value;
    }

    get items(): Item[] {
        return Array
            .from({ length: 10 })
            .map((e, i) => i + 1)
            .map(e => ({
                value: e,
                name: String(e) + (e > 1 ? ' nights' : ' night'),
            }));
    }
}
