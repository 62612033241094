































































import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { Vue, Component } from 'vue-property-decorator';
import ChainService, { ChainServiceS } from '../chain.service';
import ChainGroup, { groupNames } from '../interfaces/chain-group.enum';

@Component({
    components: {
        CustomSelect,
    },
})
export default class ChainFilters extends Vue {
    @Inject(ChainServiceS)
    private chainService!: ChainService;

    @Inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @Inject(ClusterServiceS)
    private clusterService!: ClusterService;

    public brand: string = '';
    public region: string = '';
    public country: string = '';

    public get isClusterView() {
        return this.$route.name!.includes('.cluster');
    }

    public get isLoading() {
        return this.chainService.isDictionaryLoading;
    }

    public get needCountryFilter() {
        return this.$route.params.group !== ChainGroup.COUNTRY;
    }

    public get needRegionFilter() {
        return true
            && this.$route.params.group !== ChainGroup.COUNTRY
            && this.$route.params.group !== ChainGroup.REGION;
    }

    public get groupItems() {
        return $enum(ChainGroup)
            .map(value => ({
                value,
                name: groupNames[value],
            }));
    }

    public get settings() {
        return this.chainService.settings;
    }

    public get group() {
        return this.settings.groupBy;
    }

    public set group(value: ChainGroup) {
        this.settings.groupBy = value;
    }

    public get providerItems() {
        const { providerFilter } = this.providersService;

        return this.providersService.toItemsList(providerFilter, true);
    }

    public get provider() {
        return this.chainService.settings.provider;
    }

    public get brandItems() {
        return this.chainService.getItemSet('brand');
    }

    public get regionItems() {
        return this.chainService.getItemSet('region');
    }

    public get countryItems() {
        return this.chainService.getItemSet('country');
    }

    public get cityItems() {
        return this.chainService.getItemSet('city');
    }

    public set provider(value: string) {
        this.chainService.settings.provider = value;
        this.clusterService.saveCurrentProvider(value);
    }
}
