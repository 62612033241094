











































import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import Currency from '@/modules/common/components/currency.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import EventsModel from '@/modules/events/models/events.model';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
        DateFilter: (value : (Date |null)) => {
            if (!value) return '';
            return moment(value).format('ddd, MMM DD, YYYY');
        },
    },
    components: { Currency, Flag },
})

export default class RatesClusterTooltip extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: Number,
        required: false,
    })
    public hotelId!: number;

    public containerHeight: number = 0;
    public isMounted = false;

    get price() {
        const { hotelId, day } = this;

        const mainCompsetData = this.clusterService
            .getMainCompsetData(hotelId) as RatesCompsetMainModel;

        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            competitionPercent: null,
            price: null,
            currency: null,
        };

        const mutatePriceData = () => {
            const isNoData = this.clusterRatesService
                .isNoData(day, hotelId);

            const isNa = this.clusterRatesService
                .isNa(day, hotelId);

            const isSoldOut = this.clusterRatesService
                .isSoldOut(day, hotelId);

            const haveMainCompsetData = !!mainCompsetData;

            if (isNoData) {
                priceData.isNoData = true;
                return;
            }

            if (isNa) {
                priceData.isNa = true;
                return;
            }

            if (isSoldOut) {
                priceData.isSoldOut = true;
                return;
            }

            if (haveMainCompsetData) {
                priceData.price = this.clusterRatesService
                    .getPrice(day, hotelId);

                priceData.competitionPercent = this.clusterRatesService
                    .getCompetitionPercent(day, hotelId);

                priceData.currency = this.ratesCommonService
                    .currency(mainCompsetData);

                priceData.color = this.clusterRatesService
                    .getColor(day, hotelId);
            }
        };

        mutatePriceData();

        return priceData;
    }

    get isNa() {
        const { hotelId, day } = this;

        return this.clusterRatesService
            .isNa(day, hotelId);
    }

    get date() {
        const { year, month } = this.documentFiltersService;
        return new Date(year, month, this.day);
    }

    get isLeft() {
        return this.day > this.documentFiltersService.days.length / 2;
    }

    get offset() {
        const daysCount = this.documentFiltersService.days.length;
        const segmentStep = 100 / daysCount;

        if (!this.isMounted) return { transition: '0s' };
        const leftOffset = segmentStep * (this.day + (this.isLeft ? -1 : 0));

        return {
            left: `${leftOffset}%`,
            transform: this.isLeft ? 'translate3d(-100%, -50%, 0)' : 'translate3d(0, -50%, 0)',
        };
    }

    get flagList() {
        const events = (this.getEventsCalendar()[this.day - 1] || []);
        return Array.from(
            new Set(
                events.map(e => e.countryCode),
            ),
        );
    }

    get flagsMoreBy() {
        return this.flagList.length - 3;
    }

    getEventsCalendar() {
        const { year, month } = this.documentFiltersService;
        const events = this.eventsManagerService.getHolidayEventsByMonth({ month, year }).holiday;
        const eventsCalendar: { [k: number]: EventsModel[] | null } = {};

        events.forEach(event => {
            const start = new Date(event.startDate!);
            const startIndex = start.getDate() - 1;

            eventsCalendar[startIndex] = eventsCalendar[startIndex] || [];
            eventsCalendar[startIndex]!.push(event);
        });

        return eventsCalendar;
    }

    mounted() {
        this.isMounted = true;
    }

    beforeUnmount() {
        this.isMounted = false;
    }

    get hasLocalEvents() {
        return this.eventsManagerService.hasLocalEventsByDay({ day: this.day });
    }
}
