


















































































import { Prop, Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RatesCalendarLine from './rates-calendar-line.vue';
import RatesClusterTooltip from './rates-cluster-tooltip.vue';

@Component({
    components: {
        RatesCalendarLine,
        RatesClusterTooltip,
    },
})
export default class RatesClusterList extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) ratesFiltersService!: RatesFiltersService;

    @Prop({ type: Object, default: () => ({}) }) hotelData!: ClusterHotelsRatesModel;

    activeDay: number | null = null;

    get compsetName() {
        if (!this.hotelData.compsetMain) return '';

        return this.clusterService
            .getCompsetName(
                this.hotelData.compsetMain.id,
                this.hotelData.hotelId,
            )!;
    }

    get skeleton() {
        return !this.hotelData.hotelId || this.clusterRatesService.isLoading;
    }

    get formatedScoreDifference() {
        if (this.skeleton) return '';

        const score = Math.round(this.hotelData.newTotalScore) || 0;
        return `${score > 0 ? '+' : ''}${score}`;
    }

    get isNoData() {
        return !this.compsetName || !this.compsetName.type;
    }

    setActiveDay(el: HTMLDivElement) {
        const day = Number(el.getAttribute('data-day'));

        if (day) {
            this.activeDay = day;
        }
    }

    hideTooltip() {
        this.activeDay = null;
    }

    async goToHotel() {
        this.clusterRatesService.setActivePage('list');
        this.ratesService.saveDocument(null);
        this.documentFiltersService.savePos(null);
        if (!this.isNoData) {
            this.documentFiltersService.compsetIdFromHighLevel = this.hotelData.compsetMain.id;
        }
        this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentProvider;
        await this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(this.hotelData.hotelId),
            },
        });
    }

    async onCellClick(element: HTMLDivElement) {
        const day = Number(element.getAttribute('data-day'));

        if (!this.hotelData.compsetMain) return;

        const { id: compsetId } = this.hotelData.compsetMain;
        const { hotelId } = this.hotelData;

        this.clusterRatesService.switchCurrentHotel(hotelId);
        this.clusterRatesService.setCurrentRatesClusterData();

        await this.$router.push({
            name: `${this.$route.name!}.day-rate`,
            params: {
                day: String(day),
                hotelId: String(hotelId),
                compsetId: String(compsetId),
            },
        });
    }
}
